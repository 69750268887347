import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { get, map } from 'lodash';

import loadable from '@loadable/component';
import { capfl, getLogoPath } from 'common/utils';
import { Container, Row, Column } from 'common/components/styleguide/grid';

const TextIcon = loadable(() =>
  import('common/components/styleguide/TextIcon')
);
const Icon = loadable(() => import('common/components/styleguide/Icon'));
const Link = loadable(() => import('common/components/_base/RouteLink'));
const Image = loadable(() => import('common/components/_base/Image'));

import {
  ICON_MAIL,
  ICON_PHONE,
  ICON_LOGO_FACEBOOK,
  ICON_LOGO_GOOGLE,
  ICON_LOGO_INSTAGRAM,
  ICON_LOGO_YOUTUBE
} from 'common/constants/IconConstants';

const Footer = styled.footer`
  background-color: #202020;
  padding: 50px 0;
  color: white;
  font-size: 14px;
  line-height: 28px;

  .col {
    width: 50%;
    float: left;
    padding: 0 15px;
    box-sizing: border-box;
  }

  .col:nth-child(3) ul,
  .col:nth-child(4) ul {
    border-top: 1px solid #aaa;
    padding-top: 20px;
    margin-top: 20px;
  }

  h2 {
    margin-top: 0;
    font-size: 24px;
    line-height: 32px;
    font-weight: 800;
    margin-bottom: 20px;
  }

  a {
    color: white;
    text-decoration: none;
  }

  a.email,
  a.phone {
    display: block;
  }

  .social-icons {
    margin-top: 20px;
  }

  .social-icons a {
    margin-right: 25px;
    font-size: 18px;
  }

  a:hover {
    color: #56ab2f;
  }

  img.footer-logo {
    width: 130px;
    max-width: 100%;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  @media (min-width: 776px) {
    .col {
      width: 25%;
    }
    hr.footer-separator {
      display: none;
    }

    .col:nth-child(3) ul,
    .col:nth-child(4) ul {
      border-top: none;
      padding-top: 0;
      margin-top: 0;
    }
  }
`;

const FooterVariant3 = ({ t, logo, data, categories, pages }) => {
  return (
    <Footer>
      <Container>
        <Row>
          <Column size={{ xs: 12, sm: 6, md: 3 }}>
            <a href="/">
              <Image
                path={getLogoPath(logo)}
                srcSet={{
                  '780px': '65x65.png',
                  '1240px': '130x130.png'
                }}
                size={'130x130.png'}
                alt={
                  get(logo, 'description', false) ? logo.description : 'logo'
                }
                lazyload={false}
              />
            </a>
          </Column>
          <Column size={{ xs: 12, sm: 6, md: 3 }}>
            <h2>{capfl(t('contact'))}</h2>
            {_.get(data, 'mail') && (
              <Link className={'email'} href={'mailto:' + _.get(data, 'mail')}>
                <TextIcon iconClassName={ICON_MAIL}>{_.get(data, 'mail')}</TextIcon>
              </Link>
            )}
            {_.get(data, 'phone') && (
              <Link className={'phone'} href={'tel:' + _.get(data, 'phone')}>
                <TextIcon iconClassName={ICON_PHONE}>{_.get(data, 'phone')}</TextIcon>
              </Link>
            )}
            <div className="social-icons">
              {data.facebook.active && (
                <Link
                  linkType={'facebook'}
                  rel="nofollow"
                  href={data.facebook.link}
                  target="_blank"
                >
                  <Icon className={ICON_LOGO_FACEBOOK} />
                </Link>
              )}
              {data.instagram.active && (
                <Link
                  linkType={'instagram'}
                  rel="nofollow"
                  href={data.instagram.link}
                  target="_blank"
                >
                  <Icon className={ICON_LOGO_INSTAGRAM} />
                </Link>
              )}
              {data.google.active && (
                <Link
                  linkType={'google'}
                  rel="nofollow"
                  href={data.google.link}
                  target="_blank"
                >
                  <Icon className={ICON_LOGO_GOOGLE} />
                </Link>
              )}
              {data.youtube.active && (
                <Link
                  linkType={'youtube'}
                  rel="nofollow"
                  href={data.youtube.link}
                  target="_blank"
                >
                  <Icon className={ICON_LOGO_YOUTUBE} />
                </Link>
              )}
            </div>
          </Column>

          <Column size={{ xs: 6, sm: 6, md: 3 }}>
            <ul>
              {map(pages, page => {
                return (
                  <li key={'footer-page-item-' + page.id}>
                    <Link route="static" params={{ urlTitle: page.urlTitle }}>
                      {page.title}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Column>

          <Column size={{ xs: 6, sm: 6, md: 3 }}>
            <ul>
              {map(categories, category => {
                return (
                  <li key={'footer-category-item-' + category.id}>
                    <Link route="category" params={{ id: category.urlName }}>
                      {category.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Column>
        </Row>
      </Container>
    </Footer>
  );
};

export default React.memo(FooterVariant3, true);
