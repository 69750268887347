import React from 'react';

import { Container, Row, Column } from 'common/components/styleguide/grid';
import { BlockSecondary } from 'common/components/styleguide/grid/Block';

const CopyrightVariant1 = ({ companyName, copyrightText }) => {
  return (
    <BlockSecondary
      marginConfig={{}}
      customStyle={'font-size: 0.8em;'}
      color={'gray'}
    >
      <Container>
        <Row>
          <Column>
            {new Date().getFullYear()} &copy; {companyName} | {copyrightText}
          </Column>
        </Row>
      </Container>
    </BlockSecondary>
  );
};

export default CopyrightVariant1;
