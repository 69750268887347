import React from 'react';
import { withTheme } from 'styled-components';
import { Container, Row, Column } from 'common/components/styleguide/grid';
import { Block } from 'common/components/styleguide/grid/Block';

import { withCssUnit } from 'common/components/styleguide/utils';

const CopyrightVariant3 = ({ companyName, copyrightText, theme }) => {
  return (
    <Block
      marginConfig={{}}
      backgroundColor={'black'}
      color={"white"}
      paddingConfig={{ top: true, bottom: true }}
      customStyle={`
        text-align: center;
        line-height: 200%;
        font-size: 0.8em;
        @media (min-width: ${withCssUnit(theme.breakpoints.md)}) {
          text-align: left;
          font-size: 1em;
          line-height: auto;
        }
      `}
    >
      <Container>
        <Row>
          <Column>
            &copy; {new Date().getFullYear()} {companyName} | {copyrightText}
          </Column>
        </Row>
      </Container>
    </Block>
  );
};

export default withTheme(CopyrightVariant3);
