import React from 'react';

import { Container, Row, Column } from 'common/components/styleguide/grid';
import { Block } from 'common/components/styleguide/grid/Block';

const CopyrightVariant2 = ({ companyName, copyrightText }) => {
  return (
    <Block
      marginConfig={{ top: true, bottom: true }}
      paddingConfig={{ top: true, bottom: true }}
      customStyle={'text-align:center'}
    >
      <Container>
        <Row>
          <Column>
            &copy; {new Date().getFullYear()} {companyName} | {copyrightText}
          </Column>
        </Row>
      </Container>
    </Block>
  );
};

export default CopyrightVariant2;
